import React, { useState,useEffect,useRef} from 'react';
import { Button, TextField, Stepper, Step, StepLabel } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel ,Checkbox,FormControlLabel  } from '@mui/material';
import { Typography } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ShieldIcon from '@mui/icons-material/Shield'; 
import Step0EvacuationRepatriation from '../components/Step0EvacuationRepatriation'; 
//import step0ExchangeRate from '../components/'
import Step0LastExpense  from '../components/Step0LastExpense';
import Step0Medical  from '../components/Step0Medical';
import Step0HospitalCash from '../components/Step0HospitalCash';
import Step0PersonalAccident from '../components/Step0PersonalAccident';
import Step1PersonalData from '../components/Step1PersonalData';
import Step2MedicalHistory from '../components/Step2MedicalHistory';
import Step3PolicySummary from '../components/Step3PolicySummary';
import Step4Payment from '../components/Step4Payment';


import './Home.css';

const Home = () => {
      // Fetching product data
      const [products, setProducts] = useState([]);
      useEffect(() => {
          fetch('http://birdview-001-site5.dtempurl.com/api/product/products')
            .then(response => response.json())
            .then(data => {
                setProducts(data);
            })
            .catch(error => console.error('Error loading JSON products data:', error));
      }, []);

      // Fetching conversion rates data
      const [conversionRatesData, setConversionRatesData] = useState([]);
      useEffect(() => {
          fetch('http://birdview-001-site5.dtempurl.com/api/product/GetXRates')
            .then(response => response.json())
            .then(data => {
                setConversionRatesData(data);
            })
            .catch(error => console.error('Error loading JSON conversion rates data:', error));
      }, []);

      // Fetching max entity no.
      const [maxEntityNo, setMaxEntityNo] = useState(0);
      useEffect(() => {
          fetch('http://birdview-001-site5.dtempurl.com/api/product/GetEntityNo')
            .then(response => response.json())
            .then(data => {
                setMaxEntityNo(data);
            })
            .catch(error => console.error('Error loading JSON max entity no.:', error));
      }, []);

      // Fetching premium factor data
      const [premiumFactorData, setPremiumFactorData] = useState([]);
      useEffect(() => {
          fetch('http://birdview-001-site5.dtempurl.com/api/product/GetPremiumFactor')
            .then(response => response.json())
            .then(data => {
                setPremiumFactorData(data);
            })
            .catch(error => console.error('Error loading JSON premium factor data:', error));
      }, []);

      // State to manage the active step
      const [activeStep, setActiveStep] = useState(0);
      const steps = ['Product Selection', 'Personal Data', 'Medical History', 'Policy Summary', 'Payment', 'Policy Issuance'];
      const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format to be use to initialize the date on calender

      // Add Functionality of Product
      const [checkedAddProductItems, setCheckedAddProductItems] = useState({
        evacuationRepatriation: false,
        lastExpense: false,
        medical: false,
        hospitalCash: false,
        personalAccident: false,
      });
      const isAnyProductSelected = () => {
        return Object.values(checkedAddProductItems).some(value => value === true);
      };
      
      // Function to map ProductID to state
      const setProductState = (productId) => {
        const productMapping = {
          1: 'evacuationRepatriation',
          2: 'lastExpense',
          3: 'medical',
          4: 'hospitalCash',
          5: 'personalAccident',
        };
      const productKey = productMapping[productId];
        if (productKey) {
          setCheckedAddProductItems((prevCheckedAddProductItems) => ({
            ...prevCheckedAddProductItems,
            [productKey]: true,
          }));
        }
      };

      // Parse ProductID from URL and set state
      useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const productId = parseInt(urlParams.get('ProductID'), 10);
        if (productId && !isNaN(productId)) {
          setProductState(productId);
        }
      }, []);

      // Define a function to handle checkbox changes
      const handleCheckboxAddProduct = (event) => {
        const { name, checked } = event.target;
        setCheckedAddProductItems((prevCheckedAddProductItems) => ({
          ...prevCheckedAddProductItems,
          [name]: checked,
        }));
      };
      
      // convertion Rates
      const conversionRates = {
        KES: 0,
        USD: 0,
        EUR: 0,
        GBP: 0,
      };
      for (let i = 0; i < conversionRatesData.length; i++) {
        if (conversionRatesData[i].currency === 'KES') {conversionRates.KES = conversionRatesData[i].xRate;}
      }
      for (let i = 0; i < conversionRatesData.length; i++) {
        if (conversionRatesData[i].currency === 'USD') {conversionRates.USD = conversionRatesData[i].xRate;}
      }
      for (let i = 0; i < conversionRatesData.length; i++) {
        if (conversionRatesData[i].currency === 'EUR') {conversionRates.EUR = conversionRatesData[i].xRate;}
      }
      for (let i = 0; i < conversionRatesData.length; i++) {
        if (conversionRatesData[i].currency === 'GBP') {conversionRates.GBP = conversionRatesData[i].xRate;}
      }

      // Define the product options and summary data
      // Define the product options for Evacuation and Repatriation
        const EvacuationRepatriationPlans = [[], []];
        for (let i = 0; i < products.length; i++) {
          if (products[i].product_No === 1 && products[i].cover_Type === "Individual") {EvacuationRepatriationPlans[0].push(products[i]);}
          if (products[i].product_No === 1 && products[i].cover_Type === "Family") {EvacuationRepatriationPlans[1].push(products[i]);}
        }

      // Define the product options for LastExpense
        const LastExpensePlans = [[], []];
        for (let i = 0; i < products.length; i++) {
          if (products[i].product_No === 2 && products[i].cover_Type === "Individual") {LastExpensePlans[0].push(products[i]);}
          if (products[i].product_No === 2 && products[i].cover_Type === "Family") {LastExpensePlans[1].push(products[i]);}
        }

      // Define the product options for Medical
      const MedicalPlans = [[], []];
      for (let i = 0; i < products.length; i++) {
        if (products[i].product_No === 3 && products[i].cover_Type === "Individual") {MedicalPlans[0].push(products[i]);}
        if (products[i].product_No === 3 && products[i].cover_Type === "Family") {MedicalPlans[1].push(products[i]);}
    }

      // Define the product options for Hospital Cash
      const HospitalCashPlans = [[], []];
      for (let i = 0; i < products.length; i++) {
        if (products[i].product_No === 4 && products[i].cover_Type === "Individual") {HospitalCashPlans[0].push(products[i]);}
        if (products[i].product_No === 4 && products[i].cover_Type === "Family") {HospitalCashPlans[1].push(products[i]);}
    }

      // Define the product options for Personal Accident
      const PersonalAccidentPlans = [[], []];
      for (let i = 0; i < products.length; i++) {
        if (products[i].product_No === 5 && products[i].cover_Type === "Individual") {PersonalAccidentPlans[0].push(products[i]);}
        if (products[i].product_No === 5 && products[i].cover_Type === "Family") {PersonalAccidentPlans[1].push(products[i]);}
    }      

   // Store Contact Details and also Login IDs
   const [contactAndLoginsAndCurrency, setContactAndLoginsAndCurrency] =useState({
                                              proposerName: '',
                                              currency: 'KES',
                                              loginIdEmail: '',
                                              stakeholderName: '',
                                            })
  
   // State for form inputs Evacuation & Repatriation
   const [formDataStep0EvacuationRepatriation , setFormDataStep0EvacuationRepatriation] = useState({
                                              currency: 'KES',
                                              dependantCount: '0',
                                              coverType: '',
                                              policyStartDate: '',  
                                              selectedPlan:null,
                                              totalPremium: ''
                                            });
  
   // State for form inputs Last Expense
   const [formDataStep0LastExpense , setFormDataStep0LastExpense] = useState({
                                              currency: 'KES',
                                              dependantCount: '0',
                                              coverType: '',
                                              policyStartDate: '',  
                                              selectedPlan:null,
                                              totalPremium: ''
                                            });
     // State for form inputs Medical
   const [formDataStep0Medical , setFormDataStep0Medical] = useState({
                                              currency: 'KES',
                                              dependantCount: '0',
                                              coverType: '',
                                              policyStartDate: '',
                                              selectedInpatientPlan:null,
                                              selectedOutpatientPlan:null,
                                              selectedDentalPlan:null,
                                              selectedOpticalPlan:null,
                                              totalImpatientPremium: 0,
                                              totalOutpatientPremium: 0,
                                              totalDentalPremium: 0,
                                              totalOpticalPremium: 0,
                                              totalPremium: ''
                                            }); 
     // State for form inputs hospital Cash
   const [formDataStep0HospitalCash , setFormDataStep0HospitalCash] = useState({
                                                currency: 'KES',
                                                dependantCount: '0',
                                                coverType: '',
                                                policyStartDate: '',  
                                                selectedPlan:null,
                                                totalPremium: ''
                                              }); 
     // State for form inputs Personal Accident
   const [formDataStep0PersonalAccident , setFormDataStep0PersonalAccident] = useState({
                                                currency: 'KES',
                                                dependantCount: '0',
                                                coverType: '',
                                                policyStartDate: '',  
                                                selectedPlan:null,
                                                totalPremium: ''
                                              });  
                                              
    //  Step 2 Section Settings 
    const dependantCount = useRef(0);
     
    const [formDataStep1CustomerPersonalDetails, setFormDataStep1CustomerPersonalDetails ] = useState({
                                                title:'',
                                                firstName:'',
                                                middleName:'',
                                                surname:'',
                                                dob:'',
                                                gender:'',
                                                nationality:'',
                                                countryofResidence:'',
                                                nationalIDPassportNo:'',
                                                nhif:'', 
                                                pin:'',
                                                employer:'',
                                                postalAddress:'',
                                                code:'', 
                                                town:'', 
                                                occupation:'',
                                                physicalAddress:'', 
                                                mobileNo: '',
                                                otherPhone:'',
                                                email:''
                                              });

    const [formDataStep1PersonalAccidentProduct, setformDataStep1PersonalAccidentProduct ] = useState({
                                                txtDuty: '',
                                                chkDuty: {},
                                                chkDutyOther: '',
                                                yesCheckBoxes: {
                                                  impairments: false,
                                                  seriousInjuryIllness: false,
                                                  currentHealth: false,
                                                  hazardousSportsActivities: false,
                                                  circumstances: false,
                                                  machinery: false,
                                                  medicalInsuarance: false,
                                                  extensiveTravel: false,

                                                  PAPolicy: false
                                                },
                                                impairments: '',
                                                seriousInjuryIllness: '',
                                                currentHealth: '',
                                                hazardousSportsActivities: '',
                                                circumstances: '',
                                                machinery: '',
                                                medicalInsuarance: '',
                                                extensiveTravel: '',
                                                beneficiary1Name: '',
                                                beneficiary1Age: '',
                                                beneficiary1Relationship: '',
                                                beneficiary2Name: '',
                                                beneficiary2Age: '',
                                                beneficiary2Relationship: '',

                                                PAPolicy: '',
                                                chkPADeclined: {
                                                  cancelledPolicy: false,
                                                  declinedInsuarance: false,
                                                  declinedRenewPolicy: false,
                                                  specialTerms: false,
                                                  declinedClaim: false
                                                },
                                                txtPADeclined: ''
                                              });
    
    // Step 3 Section Settings
    const [formDataStep3MedicalHistory, setFormDataStep3MedicalHistory] = useState({})

         
          

    
                          
     
        // State for validation errors step1
        const [errorsGeneralProducts, setErrorsGeneralProducts] = useState({});
        const [errorsEvacuationRepatriation, setErrorsEvacuationRepatriation] = useState({});
        const [errorsLastExpense, setErrorsLastExpense] = useState({});
        const [errorsMedical, setErrorsMedical] = useState({});
        const [errorsHospitalCash, setErrorsHospitalCash] = useState({});
        const [errorsPersonalAccident, setErrorsPersonalAccident] = useState({});
        // State for validation errors step2
        const [errorsStep2CustomerDetails, setErrorsStep2CustomerDetails] = useState({});
        const [errorsStep2PersonalAccidentForm, setErrorsStep2PersonalAccidentForm] = useState({});
      



            // Validation function for each step
            const validateStep = () => {
            let tempErrorsGeneralProducts = {};
            let tempErrorsEvacuationRepatriation = {};
            let tempErrorsLastExpense = {};
            let tempErrorsMedical = {};
            let tempErrorsHospitalCash = {};
            let tempErrorsPersonalAccident = {};
            let tempErrorsStep2CustomerDetails = {};

              //  Validating for all this general form for all Product
              if (!contactAndLoginsAndCurrency.proposerName) tempErrorsGeneralProducts.proposerName = 'First Name is required.';
              if (!contactAndLoginsAndCurrency.loginIdEmail) tempErrorsGeneralProducts.loginIdEmail = 'Email is required.';
              // if (!contactAndLoginsAndCurrency.stakeholderName) tempErrorsGeneralProducts.stakeholderName = 'Stakeholder Name is required.';

          // Step 1 validation For Evacuation And Repatriation Forms
          if (activeStep === 0 && checkedAddProductItems.evacuationRepatriation === true ) {
               // Reseting the stored value in Step 0
               step1RefStoredData.current = {};
              if (formDataStep0EvacuationRepatriation.coverType === 'per-family' && !formDataStep0EvacuationRepatriation.dependantCount) tempErrorsEvacuationRepatriation.dependantCount = 'Dependant Count is required for Per-family Cover.';
              // dependents counts should not Exceed 10 
              if (formDataStep0EvacuationRepatriation.dependantCount > 10) tempErrorsEvacuationRepatriation.dependantCount = 'Dependant Count should not exceed 10';
              // Check if the dependants count is a number and greater than 0
              // if (formDataStep0EvacuationRepatriation.dependantCount && (isNaN(formDataStep0EvacuationRepatriation.dependantCount) || formDataStep0EvacuationRepatriation.dependantCount <= 0)) { 
              //   tempErrorsEvacuationRepatriation.dependantCount = 'Dependant Count must be a number greater than 0.';
              // }
              // Check if the cover type selected, if per-person then set dependant value to 0
              // if (formDataStep0EvacuationRepatriation.coverType === 'per-person') {
              //   setFormDataStep0EvacuationRepatriation({ ...formDataStep0EvacuationRepatriation, dependantCount: 0 });
              // }
              if (!formDataStep0EvacuationRepatriation.coverType) tempErrorsEvacuationRepatriation.coverType = 'Cover Type is required.';
              // Date validation
              const today = new Date();
              const todayISO = today.toISOString().split('T')[0];
              // Convert policyStartDate to yyyy-mm-dd if it is in mm/dd/yyyy format
              let formattedPolicyStartDate = formDataStep0EvacuationRepatriation.policyStartDate;
              if (formDataStep0EvacuationRepatriation.policyStartDate.includes('/')) {
                const [month, day, year] = formDataStep0EvacuationRepatriation.policyStartDate.split('/').map(Number);
                formattedPolicyStartDate = `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
              }
              if (formattedPolicyStartDate < todayISO) {
                tempErrorsEvacuationRepatriation.policyStartDate = `Policy Start Date cannot be in the past.`;
              }
              // Selected Plan validation
              if (formDataStep0EvacuationRepatriation.selectedPlan === null ) {
                tempErrorsEvacuationRepatriation.selectedPlan = 'You must select a plan before proceeding.';
              }}
            

          // Step 1 validation For Last Expense Forms
          if (activeStep === 0 && checkedAddProductItems.lastExpense === true ) {
               // Reseting the stored value in Step 0
               step1RefStoredData.current = {};
              if (formDataStep0LastExpense.coverType === 'per-family' && !formDataStep0LastExpense.dependantCount) tempErrorsLastExpense.dependantCount = 'Dependant Count is required for Per-family Cover.';
              // dependents counts should not Exceed 10 
              if (formDataStep0LastExpense.dependantCount > 10) tempErrorsLastExpense.dependantCount = 'Dependant Count should not exceed 10';
              // Check if the dependants count is a number and greater than 0
              // if (formDataStep0LastExpense.dependantCount && (isNaN(formDataStep0LastExpense.dependantCount) || formDataStep0LastExpense.dependantCount <= 0)) { 
              //   tempErrorsLastExpense.dependantCount = 'Dependant Count must be a number greater than 0.';
              // }
              // Check if the cover type selected, if per-person then set dependant value to 0
              // if (formDataStep0LastExpense.coverType === 'per-person') {
              //   setFormDataStep0LastExpense({ ...formDataStep0LastExpense, dependantCount: 0 });
              // }
              if (!formDataStep0LastExpense.coverType) tempErrorsLastExpense.coverType = 'Cover Type is required.';
              // Date validation
              const today = new Date();
              const todayISO = today.toISOString().split('T')[0];
              // Convert policyStartDate to yyyy-mm-dd if it is in mm/dd/yyyy format
              let formattedPolicyStartDate = formDataStep0LastExpense.policyStartDate;
              if (formDataStep0LastExpense.policyStartDate.includes('/')) {
                const [month, day, year] = formDataStep0LastExpense.policyStartDate.split('/').map(Number);
                formattedPolicyStartDate = `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
              }
              if (formattedPolicyStartDate < todayISO) {
                tempErrorsLastExpense.policyStartDate = `Policy Start Date cannot be in the past.`;
              }
              // Selected Plan validation
              if (formDataStep0LastExpense.selectedPlan === null ) {
                tempErrorsLastExpense.selectedPlan = 'You must select a plan before proceeding.';
              }}
              
           // Step 1 validation For Medical Forms
          if (activeStep === 0 && checkedAddProductItems.medical === true ) {
               // Reseting the stored value in Step 0
               step1RefStoredData.current = {};
              if (formDataStep0Medical.dependantCount > 10) tempErrorsMedical.dependantCount = 'Dependant Count should not exceed 10';
              // Check if the dependants count is a number and greater than 0
              // if (formDataStep0Medical.dependantCount && (isNaN(formDataStep0Medical.dependantCount) || formDataStep0Medical.dependantCount <= 0)) { 
              //   tempErrorsMedical.dependantCount = 'Dependant Count must be a number greater than 0.';
              // }
              const today = new Date();
              const todayISO = today.toISOString().split('T')[0];
              // Convert policyStartDate to yyyy-mm-dd if it is in mm/dd/yyyy format
              let formattedPolicyStartDate = formDataStep0Medical.policyStartDate;
              if (formDataStep0Medical.policyStartDate.includes('/')) {
                const [month, day, year] = formDataStep0Medical.policyStartDate.split('/').map(Number);
                formattedPolicyStartDate = `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
              }
              if (formattedPolicyStartDate < todayISO) {
                tempErrorsMedical.policyStartDate = `Policy Start Date cannot be in the past.`;
              }
              // Selected Plan validation
              if (formDataStep0Medical.selectedInpatientPlan === null ) {
                tempErrorsMedical.selectedPlan = 'You must select a plan before proceeding.';
              } }

                // Step 1 validation For Hospital Cash Forms
          if (activeStep === 0 && checkedAddProductItems.hospitalCash === true ) {
            // Reseting the stored value in Step 0
            step1RefStoredData.current = {};
           if (formDataStep0HospitalCash.coverType === 'per-family' && !formDataStep0HospitalCash.dependantCount) tempErrorsHospitalCash.dependantCount = 'Dependant Count is required for Per-family Cover.';
           // dependents counts should not Exceed 10 
           if (formDataStep0HospitalCash.dependantCount > 10) tempErrorsHospitalCash.dependantCount = 'Dependant Count should not exceed 10';
           // Check if the dependants count is a number and greater than 0
          //  if (formDataStep0HospitalCash.dependantCount && (isNaN(formDataStep0HospitalCash.dependantCount) || formDataStep0HospitalCash.dependantCount <= 0)) { 
          //   tempErrorsHospitalCash.dependantCount = 'Dependant Count must be a number greater than 0.';
          //  }
           // Check if the cover type selected, if per-person then set dependant value to 0
          //  if (formDataStep0HospitalCash.coverType === 'per-person') {
          //    setFormDataStep0HospitalCash({ ...formDataStep0HospitalCash, dependantCount: 0 });
          //  }
           if (!formDataStep0HospitalCash.coverType) tempErrorsHospitalCash.coverType = 'Cover Type is required.';
           // Date validation
           const today = new Date();
           const todayISO = today.toISOString().split('T')[0];
           // Convert policyStartDate to yyyy-mm-dd if it is in mm/dd/yyyy format
           let formattedPolicyStartDate = formDataStep0HospitalCash.policyStartDate;
           if (formDataStep0HospitalCash.policyStartDate.includes('/')) {
             const [month, day, year] = formDataStep0HospitalCash.policyStartDate.split('/').map(Number);
             formattedPolicyStartDate = `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
           }
           if (formattedPolicyStartDate < todayISO) {
            tempErrorsHospitalCash.policyStartDate = `Policy Start Date cannot be in the past.`;
           }
           // Selected Plan validation
           if (formDataStep0HospitalCash.selectedPlan === null ) {
            tempErrorsHospitalCash.selectedPlan = 'You must select a plan before proceeding.';
           }}


            // Step 1 validation For Personal Accident Forms
          if (activeStep === 0 && checkedAddProductItems.personalAccident === true ) {
            // Reseting the stored value in Step 0
            step1RefStoredData.current = {};
           if (formDataStep0PersonalAccident.coverType === 'per-family' && !formDataStep0PersonalAccident.dependantCount) tempErrorsPersonalAccident.dependantCount = 'Dependant Count is required for Per-family Cover.';
           // dependents counts should not Exceed 10 
           if (formDataStep0PersonalAccident.dependantCount > 10) tempErrorsPersonalAccident.dependantCount = 'Dependant Count should not exceed 10';
           // Check if the dependants count is a number and greater than 0
          //  if (formDataStep0PersonalAccident.dependantCount && (isNaN(formDataStep0PersonalAccident.dependantCount) || formDataStep0PersonalAccident.dependantCount <= 0)) { 
          //   tempErrorsPersonalAccident.dependantCount = 'Dependant Count must be a number greater than 0.';
          //  }
           // Check if the cover type selected, if per-person then set dependant value to 0
          //  if (formDataStep0PersonalAccident.coverType === 'per-person') {
          //    setFormDataStep0PersonalAccident({ ...formDataStep0PersonalAccident, dependantCount: 0 });
          //  }
           if (!formDataStep0PersonalAccident.coverType) tempErrorsPersonalAccident.coverType = 'Cover Type is required.';
           // Date validation
           const today = new Date();
           const todayISO = today.toISOString().split('T')[0];
           // Convert policyStartDate to yyyy-mm-dd if it is in mm/dd/yyyy format
           let formattedPolicyStartDate = formDataStep0PersonalAccident.policyStartDate;
           if (formDataStep0PersonalAccident.policyStartDate.includes('/')) {
             const [month, day, year] = formDataStep0PersonalAccident.policyStartDate.split('/').map(Number);
             formattedPolicyStartDate = `${year}/${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}`;
           }
           if (formattedPolicyStartDate < todayISO) {
            tempErrorsPersonalAccident.policyStartDate = `Policy Start Date cannot be in the past.`;
           }
           // Selected Plan validation
           if (formDataStep0PersonalAccident.selectedPlan === null ) {
            tempErrorsPersonalAccident.selectedPlan = 'You must select a plan before proceeding.';
           }}

          //  Step 2 Form validations
           if(activeStep === 1){
            // Validation Customer Details
            if (!formDataStep1CustomerPersonalDetails.title) tempErrorsStep2CustomerDetails.title = 'Title Selection is required.';
            if (!formDataStep1CustomerPersonalDetails.firstName) tempErrorsStep2CustomerDetails.firstName= 'First Name is required.';
            if (!formDataStep1CustomerPersonalDetails.surname) tempErrorsStep2CustomerDetails.surname= 'Surname  is required.';
            if (!formDataStep1CustomerPersonalDetails.dob) tempErrorsStep2CustomerDetails.dob= 'Date Of Birth  is required.';
            if (!formDataStep1CustomerPersonalDetails.gender) tempErrorsStep2CustomerDetails.gender= 'Select Gender  is required.';
            if (!formDataStep1CustomerPersonalDetails.nationality) tempErrorsStep2CustomerDetails.nationality= 'Select Nationality  is required.';
            if (!formDataStep1CustomerPersonalDetails.countryofResidence) tempErrorsStep2CustomerDetails.countryofResidence= 'Select Country Residence  is required.';
            if (!formDataStep1CustomerPersonalDetails.nationalIDPassportNo) tempErrorsStep2CustomerDetails.nationalIDPassportNo= 'National ID or Passport No.  is required.';
            if (!formDataStep1CustomerPersonalDetails.town) tempErrorsStep2CustomerDetails.town= 'Town is required.';
            if (!formDataStep1CustomerPersonalDetails.mobileNo) tempErrorsStep2CustomerDetails.mobileNo= 'Mobile Number is required.';
            if (!formDataStep1CustomerPersonalDetails.email) tempErrorsStep2CustomerDetails.email= 'Email is required.';
            
            // Validation Personal Accident Product Form

          }
 
            setErrorsGeneralProducts(tempErrorsGeneralProducts)
            setErrorsEvacuationRepatriation(tempErrorsEvacuationRepatriation);
            setErrorsLastExpense(tempErrorsLastExpense);
            setErrorsMedical(tempErrorsMedical);
            setErrorsHospitalCash(tempErrorsHospitalCash);
            setErrorsPersonalAccident(tempErrorsPersonalAccident);
            setErrorsStep2CustomerDetails(tempErrorsStep2CustomerDetails);
            return ((Object.keys(tempErrorsGeneralProducts).length === 0) && (Object.keys(tempErrorsEvacuationRepatriation).length === 0) && (Object.keys(tempErrorsLastExpense).length === 0) && (Object.keys(tempErrorsMedical).length === 0) && (Object.keys(tempErrorsHospitalCash).length === 0) && (Object.keys(tempErrorsLastExpense).length === 0) && (Object.keys(tempErrorsMedical).length === 0) && (Object.keys(tempErrorsStep2CustomerDetails).length === 0) );
          };

            // Initialize ref for step1
              const step1RefStoredData = useRef({});
              const [dependantData,setDependantData]  = useState({});

              // Function to add or update form data
              const addFormData = (formType, data) => {
                step1RefStoredData.current = {
                  ...step1RefStoredData.current,
                  [formType]: data,
                };
              };

              const getDependantData = (DependantDetailsStep1) => {
                setDependantData(DependantDetailsStep1)
                console.log("***********DEPENDAT DATA*******************")
                console.log( dependantData)
                
                
              };

                           
            // Handle Log In button click
            // Set log in card visibility
            const [open, setOpen] = useState(false);
            const handleLogIn = () => {
              setOpen(true);
            };
            const handleCloseLogInCard = () => {
              setOpen(false);
            };
            const handleLogInButton = () => {

            };

              // Handle next button click
              const handleNext = () => {
                // Validation logic
                if (validateStep()) {

                  // Store Step1 Data for each product type
                  if (activeStep === 0) {
                    if (checkedAddProductItems.evacuationRepatriation) {
                      const evacuationRepatriationFormData = {
                        proposerName: contactAndLoginsAndCurrency.proposerName,
                        currency: contactAndLoginsAndCurrency.currency,
                        loginIdEmail: contactAndLoginsAndCurrency.loginIdEmail,
                        // stakeholderName: contactAndLoginsAndCurrency.stakeholderName,
                        dependantCount: formDataStep0EvacuationRepatriation.dependantCount,
                        coverType: formDataStep0EvacuationRepatriation.coverType,
                        policyStartDate: formDataStep0EvacuationRepatriation.policyStartDate,
                        selectedPlan: formDataStep0EvacuationRepatriation.selectedPlan,
                      };
                      addFormData('evacuationRepatriation', evacuationRepatriationFormData);
                    }

                    if (checkedAddProductItems.lastExpense) {
                      const lastExpenseFormData = {
                        proposerName: contactAndLoginsAndCurrency.proposerName,
                        currency: contactAndLoginsAndCurrency.currency,
                        loginIdEmail: contactAndLoginsAndCurrency.loginIdEmail,
                        // stakeholderName: contactAndLoginsAndCurrency.stakeholderName,
                        dependantCount: formDataStep0LastExpense.dependantCount,
                        coverType: formDataStep0LastExpense.coverType,
                        policyStartDate: formDataStep0LastExpense.policyStartDate,
                        selectedPlan: formDataStep0LastExpense.selectedPlan,
                      };
                      addFormData('lastExpense', lastExpenseFormData);
                    }

                    if (checkedAddProductItems.medical) {
                      const medicalFormData = {
                        proposerName: contactAndLoginsAndCurrency.proposerName,
                        currency: contactAndLoginsAndCurrency.currency,
                        loginIdEmail: contactAndLoginsAndCurrency.loginIdEmail,
                        // stakeholderName: contactAndLoginsAndCurrency.stakeholderName,
                        dependantCount: formDataStep0Medical.dependantCount,
                        policyStartDate: formDataStep0Medical.policyStartDate,
                        selectedPlan: formDataStep0Medical.selectedPlan,
                      };
                      addFormData('medical', medicalFormData);
                    }

                    if (checkedAddProductItems.hospitalCash) {
                      const hospitalCashFormData = {
                        proposerName: contactAndLoginsAndCurrency.proposerName,
                        currency: contactAndLoginsAndCurrency.currency,
                        loginIdEmail: contactAndLoginsAndCurrency.loginIdEmail,
                        // stakeholderName: contactAndLoginsAndCurrency.stakeholderName,
                        dependantCount: formDataStep0HospitalCash.dependantCount,
                        coverType: formDataStep0HospitalCash.coverType,
                        policyStartDate: formDataStep0HospitalCash.policyStartDate,
                        selectedPlan: formDataStep0HospitalCash.selectedPlan,
                      };
                      addFormData('hospitalCash', hospitalCashFormData);
                    }

                    if (checkedAddProductItems.personalAccident) {
                      const personalAccidentFormData = {
                        proposerName: contactAndLoginsAndCurrency.proposerName,
                        currency: contactAndLoginsAndCurrency.currency,
                        loginIdEmail: contactAndLoginsAndCurrency.loginIdEmail,
                        // stakeholderName: contactAndLoginsAndCurrency.stakeholderName,
                        dependantCount: formDataStep0PersonalAccident.dependantCount,
                        coverType: formDataStep0PersonalAccident.coverType,
                        policyStartDate: formDataStep0PersonalAccident.policyStartDate,
                        selectedPlan: formDataStep0PersonalAccident.selectedPlan,
                      };
                      addFormData('personalAccident', personalAccidentFormData);
                    }

                    console.log("*********************Step1 DATA********************");
                    console.log(step1RefStoredData.current); 
                    
                    // Function to get data from step1 
                    const getStep1Data = (key) => {
                      return step1RefStoredData.current[key] || {}; // Return empty object if key not found
                    };

                    // Extracting Value from the key values
                    const evacuationRepatriationData = getStep1Data('evacuationRepatriation');
                    const lastExpenseData = getStep1Data('lastExpense');
                    const medicalData = getStep1Data('medical');
                    const hospitalCashData = getStep1Data('hospitalCash');
                    const personalAccidentData = getStep1Data('personalAccident');

                    // Access specific values, defaulting to 0 if not present
                    const evacuationCount = evacuationRepatriationData.dependantCount || 0;
                    const lastExpenseCount = lastExpenseData.dependantCount || 0;
                    const medicalCount = medicalData.dependantCount || 0;
                    const hospitalCashCount = hospitalCashData.dependantCount || 0;
                    const personalAccidentCount = personalAccidentData.dependantCount || 0;

                    // Find the maximum dependantCount
                    const maxDependantCount = Math.max(evacuationCount, lastExpenseCount, medicalCount, hospitalCashCount, personalAccidentCount);
                   
                    // Initializing Dependant Count
                    dependantCount.current = maxDependantCount;

                  }

                   // Store Step2 Data for customer Details
                   if(activeStep === 1){
                  // Fetch Dependant Data from Step 2
                  getDependantData();
                  // Creating the customerDetailsData object
                  const customerDetailsData = {
                    title: formDataStep1CustomerPersonalDetails.title,
                    firstName: formDataStep1CustomerPersonalDetails.firstName,
                    middleName: formDataStep1CustomerPersonalDetails.middleName,
                    surname: formDataStep1CustomerPersonalDetails.surname,
                    dob: formDataStep1CustomerPersonalDetails.dob,
                    gender: formDataStep1CustomerPersonalDetails.gender,
                    nationality: formDataStep1CustomerPersonalDetails.nationality,
                    countryofResidence: formDataStep1CustomerPersonalDetails.countryofResidence,
                    nationalIDPassportNo: formDataStep1CustomerPersonalDetails.nationalIDPassportNo,
                    nhif: formDataStep1CustomerPersonalDetails.nhif,
                    pin: formDataStep1CustomerPersonalDetails.pin,
                    employer: formDataStep1CustomerPersonalDetails.employer,
                    postalAddress: formDataStep1CustomerPersonalDetails.postalAddress,
                    code: formDataStep1CustomerPersonalDetails.code,
                    town: formDataStep1CustomerPersonalDetails.town,
                    occupation: formDataStep1CustomerPersonalDetails.occupation,
                    physicalAddress: formDataStep1CustomerPersonalDetails.physicalAddress,
                    mobileNo: formDataStep1CustomerPersonalDetails.mobileNo,
                    otherPhone: formDataStep1CustomerPersonalDetails.otherPhone,
                    email: formDataStep1CustomerPersonalDetails.email,
                  
                    // Adding an array of objects inside customerDetailsData
                    dependants:  dependantData
                     
                  };
                  // Additing Data for Step2 Customer Data
                  addFormData('customerDetailsAndDependants',customerDetailsData);
                  console.log("*************COMBINE DATA*******************")
                  console.log(step1RefStoredData.current);
                   }

                  const personalAccidentProductData = {
                     
                  };

                  // Process Next Page
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
              };


            // Handle back button click
              const handleBack = () => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              };
            // Handle reset button click
              const handleReset = () => {
                setActiveStep(0);
              };

  // Update personal details function
  const updatePersonalData = (formDataStep1CustomerPersonalDetails) => {
    const entity = {
      EntityNo: maxEntityNo,
      FName: formDataStep1CustomerPersonalDetails.firstName,
      MName: formDataStep1CustomerPersonalDetails.middleName,
      SName: formDataStep1CustomerPersonalDetails.surname,
      Sex: formDataStep1CustomerPersonalDetails.gender,
      Title: formDataStep1CustomerPersonalDetails.title,
      DOB: formDataStep1CustomerPersonalDetails.dob,
      IDType: '',
      IDNo: formDataStep1CustomerPersonalDetails.nationalIDPassportNo,
      NHIFNo: formDataStep1CustomerPersonalDetails.nhif,
      PINNo: formDataStep1CustomerPersonalDetails.pin,
      StatusDate: '5/5/5555',
      Status: '',
      Image: '',
      DOR: '5/5/5555',
      PhysicalAddress: formDataStep1CustomerPersonalDetails.physicalAddress,
      PostalNo: formDataStep1CustomerPersonalDetails.postalAddress,
      PostalCode: formDataStep1CustomerPersonalDetails.code,
      AreaNo: 0,
      CountryResidence: formDataStep1CustomerPersonalDetails.countryofResidence,
      MobileNumber: formDataStep1CustomerPersonalDetails.mobileNo,
      MobileNumber1: formDataStep1CustomerPersonalDetails.otherPhone,
      Email: formDataStep1CustomerPersonalDetails.email,
      Occupation: formDataStep1CustomerPersonalDetails.occupation,
      Employer: formDataStep1CustomerPersonalDetails.employer,
      CreatedBy: 0,
      CreatedDate: '5/5/5555',
      CustomerAC: 0,
      Website: '',
      Nationality: formDataStep1CustomerPersonalDetails.nationality,
      Password: '',
    }
    fetch('https://localhost:44322/api/product/SaveProposerDetails', {
      method: 'post',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
      body:JSON.stringify(entity)
    })
    .then(response => response.json())
    .then(data => {})
  }
  // Save name and email function
  let currEntityNo = parseInt(maxEntityNo) + 1;
  const saveNameEmail = () => {
    if (validateStep()) {
      const entity ={
        EntityNo: parseInt(maxEntityNo) + 1,
        FName: contactAndLoginsAndCurrency.proposerName,
        Email: contactAndLoginsAndCurrency.loginIdEmail,
        EntityType: 'PRI'
      }
      fetch('https://birdview-001-site5.dtempurl.com:44322/api/product/SaveProposer', {
        method: 'post',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }),
        body:JSON.stringify(entity)
      })
      .then(response => response.json())
      .then(data => {})
    }      
  }

  // Save dependant details function
  const saveDependantDetails = (i) => {
    const entity ={
      DependantNo: dependantData[i].dependantNo,
      PrincipalNo: dependantData[i].prinicpaleNo,
      FName: dependantData[i].firstName,
      MName: dependantData[i].middleName,
      LName: dependantData[i].surname,
      Sex: '',
      DOB: dependantData[i].dob,
      Relationship: dependantData[i].relationship
    }
    fetch('https://localhost:44322/api/product/AddDependant', {
      method: 'post',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }),
      body:JSON.stringify(entity)
    })
    .then(response => response.json())
    .then(data => {})
  }

  // Calculating premium summary
  const calculatePremium = (planProductId, dependantCount, displayedPlans) => {
    const plan = displayedPlans.find(p => p.product_Id === planProductId);
    if (!plan) {
      // console.error(`Plan with product_Id ${planProductId} not found`);
      return {
        premium: 0,
        itl: 0,
        pcf: 0,
        stampDuty: 0,
        total: 0
      };
    }

    let nPremium = 0;

    if (plan.cover_Type === 'Family') {
      // Get premium factor for product
      const premiumFactor = [];
      for (let i = 0; i < premiumFactorData.length; i++) {
        if (plan.product_Id === premiumFactorData[i].product_Id) {premiumFactor.push(premiumFactorData[i]);}
      }

      for (let i = 0; i < premiumFactor.length; i++) {
        nPremium += plan.premium * (premiumFactor[i].pFactor / 100);
        console.log(nPremium);
        if (i + 1 > parseInt(dependantCount)) {break}
      }
    } else if (plan.cover_Type === 'Individual') {
      nPremium = plan.premium * (parseInt(dependantCount) + 1);
    }
    
    const newPremium = nPremium * conversionRates[contactAndLoginsAndCurrency.currency];
    const newITL = newPremium * 0.002;
    const newPCF = newPremium * 0.0025;
    const newStampDuty = 40 * conversionRates[contactAndLoginsAndCurrency.currency];
    const newTotal = newPremium + newITL + newPCF;

    return {
      premium: newPremium.toFixed(4),
      itl: newITL.toFixed(4),
      pcf: newPCF.toFixed(4),
      stampDuty: newStampDuty.toFixed(4),
      total: newTotal.toFixed(4)
    }  
  };

  // Selected plans
  const [selectedPlans, setSelectedPlans] = useState([]);
  const saveSelectedPlans = () => {
    const ERD = formDataStep0EvacuationRepatriation;
    const LED = formDataStep0LastExpense;
    const MD = formDataStep0Medical;
    const HCD = formDataStep0HospitalCash;
    const PAD = formDataStep0PersonalAccident;

    if (ERD.selectedPlan) {
      if (ERD.coverType === "per-person") {
        const newPlan = EvacuationRepatriationPlans[0].find(p => p.product_Id === ERD.selectedPlan);
        setSelectedPlans(prevPlans => [...prevPlans, newPlan])
      } else if (ERD.coverType === "per-family") {
        const newPlan = EvacuationRepatriationPlans[1].find(p => p.product_Id === ERD.selectedPlan);
        setSelectedPlans(prevPlans => [...prevPlans, newPlan])
      }              
    }
    if (LED.selectedPlan) {
      if (LED.coverType === "per-person") {
        const newPlan = LastExpensePlans[0].find(p => p.product_Id === LED.selectedPlan);
        setSelectedPlans(prevPlans => [...prevPlans, newPlan])
      } else if (LED.coverType === "per-family") {
        const newPlan = LastExpensePlans[1].find(p => p.product_Id === LED.selectedPlan);
        setSelectedPlans(prevPlans => [...prevPlans, newPlan])
      }              
    }
    if (MD.selectedInpatientPlan) {
      if (MD.coverType === "per-person") {
        const newImpatientPlan = MedicalPlans[0].find(p => p.product_Id === MD.selectedInpatientPlan);
        const newOutpatientPlan = MedicalPlans[0].find(p => p.product_Id === MD.selectedOutpatientPlan);
        const newDentalPlan = MedicalPlans[0].find(p => p.product_Id === MD.selectedDentalPlan);
        const newOpticalPlan = MedicalPlans[0].find(p => p.product_Id === MD.selectedOpticalPlan);
        setSelectedPlans(prevPlans => [
          ...prevPlans, ...[newImpatientPlan, newOutpatientPlan, newDentalPlan, newOpticalPlan].filter(plan => plan)
        ]);
      } else if (MD.coverType === "per-family") {
        const newImpatientPlan = MedicalPlans[1].find(p => p.product_Id === MD.selectedInpatientPlan);
        const newOutpatientPlan = MedicalPlans[1].find(p => p.product_Id === MD.selectedOutpatientPlan);
        const newDentalPlan = MedicalPlans[1].find(p => p.product_Id === MD.selectedDentalPlan);
        const newOpticalPlan = MedicalPlans[1].find(p => p.product_Id === MD.selectedOpticalPlan);
        setSelectedPlans(prevPlans => [
          ...prevPlans, ...[newImpatientPlan, newOutpatientPlan, newDentalPlan, newOpticalPlan].filter(plan => plan)
        ]);
        console.log(newImpatientPlan)
      }
    }
    if (HCD.selectedPlan) {
      if (HCD.coverType === "per-person") {
        const newPlan = HospitalCashPlans[0].find(p => p.product_Id === HCD.selectedPlan);
        setSelectedPlans(prevPlans => [...prevPlans, newPlan])
      } else if (HCD.coverType === "per-family") {
        const newPlan = HospitalCashPlans[1].find(p => p.product_Id === HCD.selectedPlan);
        setSelectedPlans(prevPlans => [...prevPlans, newPlan])
      }              
    }
    if (PAD.selectedPlan) {
      if (PAD.coverType === "per-person") {
        const newPlan = PersonalAccidentPlans[0].find(p => p.product_Id === PAD.selectedPlan);
        setSelectedPlans(prevPlans => [...prevPlans, newPlan])
      } else if (PAD.coverType === "per-family") {
        const newPlan = PersonalAccidentPlans[1].find(p => p.product_Id === PAD.selectedPlan);
        setSelectedPlans(prevPlans => [...prevPlans, newPlan])
      }              
    }    
  }

  // Step 2 next
  const [step2Next, setStep2Next] = useState(false);
  // Refer or decline based on medical history
  const [medicalHistoryReferDecline, setMedicalHistoryReferDecline] = useState({
    refer: false,
    decline: false
  })


  // Render step content based on the active step index
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className='mt-2'> 
       {/* Importing Forms Of different Products from folder ../components */}
       { checkedAddProductItems.evacuationRepatriation === true &&  
          <Step0EvacuationRepatriation
          conversionRates = {conversionRates}
          formDataStep0EvacuationRepatriation={formDataStep0EvacuationRepatriation}
          setFormDataStep0EvacuationRepatriation={setFormDataStep0EvacuationRepatriation}
          errors={errorsEvacuationRepatriation}
          setErrors={setErrorsEvacuationRepatriation}
          EvacuationRepatriationPlans={EvacuationRepatriationPlans}
          today={today}
          calculatePremium={calculatePremium}
        /> }

       { checkedAddProductItems.lastExpense === true &&   
        <Step0LastExpense 
         conversionRates = {conversionRates}
         formDataStep0LastExpense={formDataStep0LastExpense}
         setFormDataStep0LastExpense={setFormDataStep0LastExpense}
         errors={errorsLastExpense}
         setErrors={setErrorsLastExpense}
         LastExpensePlans={LastExpensePlans}
         today={today}
         calculatePremium={calculatePremium}
       />  }

       { checkedAddProductItems.medical === true &&   
        <Step0Medical 
         conversionRates = {conversionRates}
         formDataStep0Medical={formDataStep0Medical}
         setFormDataStep0Medical={setFormDataStep0Medical}
         errors={errorsMedical}
         setErrors={setErrorsMedical}
         MedicalPlans={MedicalPlans}
         today={today}
         calculatePremium={calculatePremium}
       />  }
 
      { checkedAddProductItems.hospitalCash  === true &&  
       <Step0HospitalCash 
         conversionRates = {conversionRates}
         formDataStep0HospitalCash={formDataStep0HospitalCash}
         setFormDataStep0HospitalCash={setFormDataStep0HospitalCash}
         errors={errorsHospitalCash}
         setErrors={setErrorsHospitalCash}
         HospitalCashPlans={HospitalCashPlans}
         today={today}
         calculatePremium={calculatePremium}
       /> } 

       { checkedAddProductItems.personalAccident  === true &&  
       <Step0PersonalAccident 
         conversionRates = {conversionRates}
         formDataStep0PersonalAccident={formDataStep0PersonalAccident}
         setFormDataStep0PersonalAccident={setFormDataStep0PersonalAccident}
         errors={errorsPersonalAccident}
         setErrors={setErrorsPersonalAccident}
         PersonalAccidentPlans={PersonalAccidentPlans}
         today={today}
         calculatePremium={calculatePremium}
       />}  

        </div>


        );
      case 1:
        return ( 
          <div>
            <Step1PersonalData
            today={today}
            formDataStep1CustomerPersonalDetails={formDataStep1CustomerPersonalDetails}
            setFormDataStep1CustomerPersonalDetails ={setFormDataStep1CustomerPersonalDetails}
            formDataStep1PersonalAccidentProduct = {formDataStep1PersonalAccidentProduct}
            setformDataStep1PersonalAccidentProduct = {setformDataStep1PersonalAccidentProduct}
            errors={errorsStep2CustomerDetails}
            setErrors={setErrorsStep2CustomerDetails}
            errorsStep2PersonalAccidentForm={errorsStep2PersonalAccidentForm}
            setErrorsStep2PersonalAccidentForm={setErrorsStep2PersonalAccidentForm}
            dependantCount={dependantCount.current}
            getDependantData={getDependantData}
            checkedAddProductItemsEvacuationRepatriation={checkedAddProductItems.evacuationRepatriation}
            checkedAddProductItemsLastExpense={checkedAddProductItems.lastExpense}
            checkedAddProductItemsMedical={checkedAddProductItems.medical}
            checkedAddProductItemsHospitalCash={checkedAddProductItems.hospitalCash}
            checkedAddProductItemsPersonalAccident={checkedAddProductItems.personalAccident}
            maxEntityNo={maxEntityNo}
            />

            {/* { checkedAddProductItems.personalAccident === true &&  
              <Step1PersonalAccidentProductForm
              formDataStep1PersonalAccidentProduct={formDataStep1PersonalAccidentProduct}
              setformDataStep1PersonalAccidentProduct={setformDataStep1PersonalAccidentProduct}
              errors={errorsStep2PersonalAccidentForm}
              setErrors={setErrorsStep2PersonalAccidentForm}
              />}   */}

          </div>
        );
      case 2:
        return (
          <div>
            <Step2MedicalHistory 
            formDataStep1CustomerPersonalDetails={formDataStep1CustomerPersonalDetails}
            formDataStep3MedicalHistory={formDataStep3MedicalHistory}
            setFormDataStep3MedicalHistory={setFormDataStep3MedicalHistory}
            step2Next={step2Next}
            setStep2Next={setStep2Next}
            formDataStep0EvacuationRepatriation={formDataStep0EvacuationRepatriation}
            formDataStep0LastExpense={formDataStep0LastExpense}
            formDataStep0Medical={formDataStep0Medical}
            formDataStep0HospitalCash={formDataStep0HospitalCash}
            formDataStep0PersonalAccident={formDataStep0PersonalAccident}
            medicalHistoryReferDecline={medicalHistoryReferDecline}
            setMedicalHistoryReferDecline={setMedicalHistoryReferDecline}
            handleNext={handleNext}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <Step3PolicySummary
            selectedPlans={selectedPlans}
            formDataStep0EvacuationRepatriation={formDataStep0EvacuationRepatriation}
            formDataStep0LastExpense={formDataStep0LastExpense}
            formDataStep0Medical={formDataStep0Medical}
            formDataStep0HospitalCash={formDataStep0HospitalCash}
            formDataStep0PersonalAccident={formDataStep0PersonalAccident}
            calculatePremium={calculatePremium}
            conversionRates={conversionRates}
            contactAndLoginsAndCurrency={contactAndLoginsAndCurrency}
            />
          </div>
        );
      case 4:
        return (
          <div>
            <Step4Payment
            checkedAddProductItems={checkedAddProductItems}
            formDataStep0EvacuationRepatriation={formDataStep0EvacuationRepatriation}
            formDataStep0LastExpense={formDataStep0LastExpense}
            formDataStep0Medical={formDataStep0Medical}
            formDataStep0HospitalCash={formDataStep0HospitalCash}
            formDataStep0PersonalAccident={formDataStep0PersonalAccident}
            />
          </div>
        );
      case 5:
        return (
          <div>
            <p>Your policy has been issued successfully!</p>
            {/* Include policy issuance confirmation details here */}
          </div>
        );
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    // "body" div
    <div style={{ padding: 0 }}>
       <style>
          {`
          .shadow-div {
             background-color: white;
             box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
             border-radius: 8px;
             padding: 16px;
            }
         `}
      </style>

      {/* head 1 */}
      <div>
        <img className='logoImage'   src="images/logo.jpeg"  alt="Logo" style={{  width: '10%',  height: 'auto',    objectFit: 'contain',   display: 'block', margin: '0 0',   }}  />
      </div>

      {/* head 2 */}
      <div>
        <div style={{ backgroundColor: '#E42D2C' }} className="p-4 mb-2">
          <div className="container mx-auto">
          <ShieldIcon style={{ color: 'white', marginRight: '8px' }} />
            <Typography variant="h5" className="text-white text-xl md:text-2xl font-semibold">
              Get Your Coverage
            </Typography>
          </div>
        </div>
      </div>

      {/* head 3 */}
      <div style={{backgroundColor:'#157EBC'}} className="flex bg-blue-900 p-3">
        <div className="container mx-auto flex items-center justify-center bg-opacity-70">
          <div className="flex flex-col items-center space-y-4 text-white">
            <div style={{ width: '100%', maxWidth: '100%' }} >
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step   key={label}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "#ffffff"
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "#ffffff"
                    },
                     
                    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                      color: "#ffffff"
                    },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "gray"
                    }
                  }}
                  
                  >
                    <StepLabel
                      sx={{
                        '& .MuiStepLabel-root': {
                          flexDirection: { xs: 'column', sm: 'row' }, // Stack steps vertically on small screens
                          alignItems: { xs: 'flex-start', sm: 'center' }, // Align items accordingly
                        },
                        '& .MuiStepLabel-root .Mui-completed': {
                          color: 'secondary.dark', // circle color (COMPLETED)
                        },
                        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                          color: 'grey.500', // Just text label (COMPLETED)
                          fontSize: { xs: '0.75rem', sm: '1rem' }, // Responsive font size for completed steps
                        },
                        '& .MuiStepLabel-root .Mui-active': {
                          color: 'secondary.main', // circle color (ACTIVE)
                        },
                        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                          color: 'common.white', // Just text label (ACTIVE)
                          fontSize: { xs: '0.75rem', sm: '1rem' }, // Responsive font size for active steps
                        },
                        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                          fill: { xs: 'white', sm: 'black' }, // Responsive color for step numbers
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
        </div>
      </div>
      
      {/* form container */}
      <div className="container mx-auto px-5">

    {/* Quote Reference Filed It keeps Visibility Till the end */}
      <div className="bg-white shadow-3xl p-4  ">
          <form>
            <div className="flex justify-end items-center">
              <div className="flex-2 flex items-center space-x-2">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="quote-ref-label">Quote Ref.</InputLabel>
                  <Select
                    labelId="quote-ref-label"
                    label="Quote Ref."
                    defaultValue=""
                    sx={{ minWidth: 120 }}
                     
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Quote 1</MenuItem>
                    <MenuItem value={2}>Quote 2</MenuItem>
                    <MenuItem value={3}>Quote 3</MenuItem>
                    {/* Add more MenuItems as needed */}
                  </Select>
                </FormControl>
                <button
                  type="button"
                  style={{backgroundColor:'#157EBC'}}
                  className="  hover:bg-blue-600 text-white text-sm px-3 py-1 rounded"
                >
                  Retrieve
                </button>
              </div>
              {/* Log In */}
              <button
                type="button"
                style={{backgroundColor:'#E42D2C', marginLeft:20}}
                className="  text-white text-sm px-3 py-1 rounded"
                onClick={handleLogIn}
              >
                Log In
              </button>
              <Dialog open={open} onClose={handleCloseLogInCard}>
                <DialogTitle>Log In</DialogTitle>
                <DialogContent>
                  {/* Log in ID */}
                  <TextField
                    id="loginID"
                    label="Log In ID"
                    variant="outlined"
                    fullWidth
                    sx={{margin: '10px auto'}}
                  />
                  <TextField
                    id="password"
                    label="Password"
                    type='password'
                    variant="outlined"
                    fullWidth
                    sx={{margin: '10px auto'}}
                  />
                  <button
                    type="button"
                    style={{backgroundColor:'#E42D2C', marginTop:20}}
                    className="  text-white text-sm px-3 py-2 rounded"
                    onClick={handleLogInButton}
                  >
                    Log In
                  </button>

                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseLogInCard}>Close</Button>
                </DialogActions>
              </Dialog>
            </div>
            <p className="text-end mt-2 text-sm md:text-xs lg:text-sm text-gray-600">
              To retrieve a quote, please select the quote ref. number after signing in.
            </p>
          </form>
        </div>

        {/* Step 1 Forms Visibility Stops after Step 1 is Completed */}
        { activeStep === 0 && 
        <div className='p-1' > 
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-2 ">

            {/* Proposer Name */}
            <div className="flex-1">
            <TextField
              required
              id="proposer-name"
              label="First Name"
              variant="outlined"
              fullWidth
              value={contactAndLoginsAndCurrency.proposerName}
              onChange={(e) => {
                setContactAndLoginsAndCurrency({ ...contactAndLoginsAndCurrency, proposerName: e.target.value });
                if (errorsGeneralProducts.proposerName) {
                  setErrorsGeneralProducts({ ...errorsGeneralProducts, proposerName: '' });
                }
              }}
              error={!!errorsGeneralProducts.proposerName}
              helperText={errorsGeneralProducts.proposerName}
            />
            </div>

            {/* Currency */}
            <div className="flex-1">
            <FormControl fullWidth variant="outlined">
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                id="currency"
                label="Currency"
                value={contactAndLoginsAndCurrency.currency}
                onChange={(e) => {
                  const selectedCurrency = e.target.value;

                  // Inline update for both states
                  setFormDataStep0LastExpense(prevState => ({
                    ...prevState,
                    currency: selectedCurrency
                  }));

                  setFormDataStep0EvacuationRepatriation(prevState => ({
                    ...prevState,
                    currency: selectedCurrency
                  }));

                  setFormDataStep0Medical(prevState => ({
                    ...prevState,
                    currency: selectedCurrency
                  }));

                  setFormDataStep0HospitalCash(prevState => ({
                    ...prevState,
                    currency: selectedCurrency
                  }));

                  setContactAndLoginsAndCurrency(prevState => ({
                    ...prevState,
                    currency: selectedCurrency
                  }));

                  setFormDataStep0PersonalAccident(prevState => ({
                    ...prevState,
                    currency: selectedCurrency
                  }));

                  

                }}
              >
                <MenuItem value="KES">KES</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="GBP">GBP</MenuItem>
              </Select>
            </FormControl>
            </div>

            {/* Login ID/Email */}
            <div className="flex-1">
            <TextField
              required
              id="login-id-email"
              label="Email"
              variant="outlined"
              fullWidth
              value={contactAndLoginsAndCurrency.loginIdEmail}
              onChange={(e) => {
                setContactAndLoginsAndCurrency({ ...contactAndLoginsAndCurrency, loginIdEmail: e.target.value });
                if (errorsGeneralProducts.loginIdEmail) {
                  setErrorsGeneralProducts({ ...errorsGeneralProducts, loginIdEmail: '' });
                }
              }}
              error={!!errorsGeneralProducts.loginIdEmail}
              helperText={errorsGeneralProducts.loginIdEmail}
            />
            </div>

            {/* Stakeholder Name */}
            {/* <div className="flex-1">
            <TextField
              required
              id="stakeholder-name"
              label="Stakeholder Name"
              variant="outlined"
              fullWidth
              value={contactAndLoginsAndCurrency.stakeholderName}
              onChange={(e) => {
                setContactAndLoginsAndCurrency({ ...contactAndLoginsAndCurrency, stakeholderName: e.target.value });
                if (errorsGeneralProducts.stakeholderName) {
                  setErrorsGeneralProducts({ ...errorsGeneralProducts, stakeholderName: '' });
                }
              }}  
              error={!!errorsGeneralProducts.stakeholderName}
              helperText={errorsGeneralProducts.stakeholderName}
            />
            </div> */}
            </div>

            {renderStepContent(activeStep)}

            {/* Checkboxes */}
            <div className="mt-2 shadow-div shadow-xl p-3">
            <h5 className="font-semibold mb-2">Add Other Products</h5>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 mb-2 gap-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="evacuationRepatriation"
                  checked={checkedAddProductItems.evacuationRepatriation}
                  onChange={handleCheckboxAddProduct}
                />
              }
              label="Evacuation Repatriation"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="lastExpense"
                  checked={checkedAddProductItems.lastExpense}
                  onChange={handleCheckboxAddProduct}
                />
              }
              label="Last Expense"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="medical"
                  checked={checkedAddProductItems.medical}
                  onChange={handleCheckboxAddProduct}
                />
              }
              label="Medical"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="hospitalCash"
                  checked={checkedAddProductItems.hospitalCash}
                  onChange={handleCheckboxAddProduct}
                />
              }
              label="Hospital Cash"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="personalAccident"
                  checked={checkedAddProductItems.personalAccident}
                  onChange={handleCheckboxAddProduct}
                />
              }
              label="Personal Accident"
            />
            </div>

            {!isAnyProductSelected() && (
              <Typography className='text-sm' color="error">Please select at least one product.</Typography>
            )}
            </div>
            </div>
            }

        { activeStep > 0 && renderStepContent(activeStep) }

        <div className="flex flex-col items-center mb-4 ">
           
          <div className="mt-4 flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0">
            <Button
              disabled={activeStep === 0 || medicalHistoryReferDecline.refer || medicalHistoryReferDecline.decline}
              variant="contained"
              color="primary"
              onClick={() => {
                if (activeStep === 1) {
                  selectedPlans.length = 0;
                
                  // CLearing total premium for medical
                  setFormDataStep0Medical({
                    ...formDataStep0Medical,
                    totalPremium: 0
                  })
                }                
                handleBack();
              }}
              style={{ marginRight: 8 }}
              fullWidth
            >
              Back
            </Button>
            <Button
              disabled={!isAnyProductSelected() || medicalHistoryReferDecline.refer || medicalHistoryReferDecline.decline} 
              variant="contained"
              color="primary"
              onClick={() => {
                if (activeStep === 0 && validateStep()) {
                  saveSelectedPlans();
                  
                  // Setting total premium for medical
                  setFormDataStep0Medical({
                    ...formDataStep0Medical,
                    totalPremium: 
                    parseFloat(formDataStep0Medical.totalInpatientPremium) +
                    parseFloat(formDataStep0Medical.totalOutpatientPremium) +
                    parseFloat(formDataStep0Medical.totalDentalPremium) +
                    parseFloat(formDataStep0Medical.totalOpticalPremium)
                  });
                }
                if (activeStep === 2) {
                  setStep2Next(true);
                } else {
                  // Always call `handleNext()` if `activeStep` is not 2
                  handleNext();
                }                
                if (activeStep === steps.length - 1) {
                  handleReset();
                }
                // if (activeStep === 0) {
                //   if (currEntityNo !== 0) {saveNameEmail();}
                //   currEntityNo = 0
                // }
                // if (activeStep === 1) {
                //   updatePersonalData(formDataStep1CustomerPersonalDetails);
                //   for (let i = 0; i < dependantData.length; i++) {saveDependantDetails(i);}  
                // }
              }}
              fullWidth
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Home;
